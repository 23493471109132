import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import About from '../static/About';
import Logo from '../../assets/images/logo_pleaseme.png'


import SignupForm from '../beta/SignupForm';

import '../../assets/stylesheets/header.css';


class Header extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            siteLoaded: false
        }
    }    

    componentDidMount() {
        setTimeout(() => {
            this.setState({
              siteLoaded: true
            });
          }, 2000);
      }
    render() {

     const { siteLoaded } = this.state

        return (
            <Container style={{paddingBottom: '2rem'}}>
                 {siteLoaded && (
             <Row className="justify-content-md-center">

                 <Col sm={12} md={5} lg={4} className="open">
                    <img src={Logo} className='logo' alt='Please Me' />
                </Col>
                </Row>
                 )}
                <Row className="justify-content-md-center">
                {siteLoaded && <About />}
                </Row>

                <Row className="justify-content-md-center">

                    {siteLoaded && <SignupForm />}

                </Row>
            </Container>
        );
    }
}

export default Header;
