import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import Header from './components/static/Header';
import './assets/stylesheets/header.css';


class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            siteLoaded: false
        }
    }    

    componentDidMount() {
        setTimeout(() => {
            this.setState({
              siteLoaded: true
            });
        }, 2000);
      }
    render() {
        const { siteLoaded } = this.state;
        return ( 
        <div className={siteLoaded ? 'test' : 'test2'}>
            <Header/>
            </div>
        );
    }
}
export default App;