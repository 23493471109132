import React from 'react';
import config from '../Firebase/config';
import {  Col, Button, Form, FormGroup, Label, Input } from 'reactstrap';

import firebase from 'firebase/app';
import 'firebase/database';


class SignupForm extends React.Component {
    constructor(props) {
        super(props);
        firebase.initializeApp(config);

        this.state = {
            name: '',
            email: '',
            submittedForm: false
        }
    }
    onFieldChange = (field, e) => {
        this.setState({
            [field]: e.target.value
        })
    }
    onSubmit = (e) => {
        const { email, name } = this.state;
        e.preventDefault();

        if(email.length > 0 && name.length > 0){
        const userListRef = firebase.database().ref('users');
        const newUserRef = userListRef.push();
        newUserRef.set(this.state);
        this.setState({
            submittedForm: true,
            emailError : false,
        })
    } else {
        this.setState({
            emailError: true
        });
    }
    }
    render() {
        const { name, emailError, submittedForm } = this.state;
        return (
                <Col className="signup-form" sm={12} md={5} >
                    <div className={submittedForm ? 'afterSubmit' : ''}></div>
                    {!submittedForm && (
                     <p className="launch">Be the first to know when we launch</p>

                    )}
                      {submittedForm && (
                        <div className="submitted">
                            You're on the list, {name}!
                    </div>)}
                    {emailError && (
                        <div>
                            Oops! Looks like you forgot to enter your name or email!
                        </div>
                    )}
                        <div className={submittedForm? 'afterSubmit' : ''}> 
                        <Form onSubmit={this.onSubmit}>
                            <FormGroup>
                                <Label for="name">name</Label>
                                <Input
                                    type="text"
                                    name="text"
                                    placeholder="full name"
                                    onChange={this.onFieldChange.bind(null, 'name')}
                                    autoComplete="off"
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="email">email</Label>
                                <Input
                                    type="email"
                                    name="email"
                                    placeholder="email address"
                                    onChange={this.onFieldChange.bind(null, 'email')}
                                    autoComplete="off"
                                />
                            </FormGroup>
                            <div className="get-on-the-list">
                                <Button size="lg">please me</Button>
                            </div>
                        </Form>
                        </div>
                </Col>

        );
    }
}

export default SignupForm;