import React from 'react';
import { Col } from 'reactstrap';


class About extends React.Component {

    render() {

        return (
                    <Col sm={12} md={5} className="about">
                    <p>Enhancing satisfaction, intimacy, and pleasure during sex goes beyond general practices. We believe that sharing personal stories, experiences, and testimonies contributes to bridging the gap, eliminating the stigma, and connecting individuals. </p>
         <p>We strive to dismiss the taboo associated with sexual dysfunctions in women by establishing a safe community and providing factual and firsthand information to ensure that no individual feels dismissed, overlooked or invalidated. </p>

Please Me: Find Your Story, Feel Heard.
                    </Col>

        );
    }
}

export default About;